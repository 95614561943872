<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni ni-chart-bar-32 text-red',
            path: '/dashboard'
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Virtual Insurance Broking Services',
            icon: 'ni ni ni ni-building text-red',
            collapsed: true 
          }"
        >
          <sidebar-item
            v-for="(product, index) in yiProducts.filter(f=>f.Product_name != 'Property')"
            :key="index"
            v-if="isShowMenu(product)"
            :link="{
              name: product.Product_name,
              // icon: 'ni ni ni ni-building text-red',
              // collapsed: true 
            }"
          >
            <sidebar-item
              v-if="!isCurrentPageCreateQuote(`products/${product.Url_slug}`)"
              :link="{
                name: 'Create Quote',
                icon: 'ni ni ni-single-copy-04 text-red',
                path: `/products/${product.Url_slug}/createQuote`
              }"
            >
            </sidebar-item>

            <a
              v-if="isCurrentPageCreateQuote(`products/${product.Url_slug}`)"
              :href="`/products/${product.Url_slug}/createQuote`"
              aria-current="page"
              class="nav-link router-link-exact-active router-link-active"
              ><span class="nav-link-text">Create Quote</span>
            </a>

            <sidebar-item
              :link="{
                name: 'Quotes',
                icon: 'ni ni ni-collection text-red',
                path: `/products/${product.Url_slug}/quotes`
              }"
            >
            </sidebar-item>
            <sidebar-item
              :link="{
                name: 'Policies',
                icon: 'ni ni ni-collection text-red',
                path: `/products/${product.Url_slug}/policies`
              }"
            >
            </sidebar-item>
          </sidebar-item>
        </sidebar-item>
        <!-- <sidebar-item
          :link="{
            name: 'Property',
            icon: 'ni ni ni ni-building text-red',
            collapsed: true 
          }"
        > -->
          <sidebar-item
            v-for="(product, index) in yiProducts.filter(f=>f.Product_name == 'Property')"
            :key="index"
            v-if="isShowMenu(product)"
            :link="{
              name: product.Product_name,
              icon: 'ni ni ni-shop text-red',
              // collapsed: true 
            }"
          >
            <sidebar-item
              v-if="!isCurrentPageCreateQuote(`products/${product.Url_slug}`)"
              :link="{
                name: 'Create Quote',
                icon: 'ni ni ni-single-copy-04 text-red',
                path: `/products/${product.Url_slug}/createQuote`
              }"
            >
            </sidebar-item>

            <a
              v-if="isCurrentPageCreateQuote(`products/${product.Url_slug}`)"
              :href="`/products/${product.Url_slug}/createQuote`"
              aria-current="page"
              class="nav-link router-link-exact-active router-link-active"
              ><span class="nav-link-text">Create Quote</span>
            </a>

            <sidebar-item
              :link="{
                name: 'Quotes',
                icon: 'ni ni ni-collection text-red',
                path: `/products/${product.Url_slug}/quotes`
              }"
            >
            </sidebar-item>
            <sidebar-item
              :link="{
                name: 'Policies',
                icon: 'ni ni ni-collection text-red',
                path: `/products/${product.Url_slug}/policies`
              }"
            >
            </sidebar-item>
          </sidebar-item>
        <!-- </sidebar-item> -->
        <sidebar-item
          v-if="show_lc"
          :link="{
            name: 'Light Commercial',
            icon: 'ni ni ni ni-delivery-fast text-red',
            collapsed: true
          }"
        >
          <sidebar-item
            v-if="!isCurrentPageCreateQuote('lightCommercial')"
            :link="{
              name: 'Create Quote',
              path: '/lightCommercial/createQuote'
            }"
          >
          </sidebar-item>

          <a
            v-if="isCurrentPageCreateQuote('lightCommercial')"
            href="/lightCommercial/createQuote"
            aria-current="page"
            class="nav-link router-link-exact-active router-link-active"
            ><span class="nav-link-text">Create Quote</span>
          </a>

          <sidebar-item
            :link="{
              name: 'Quotes',
              icon: 'ni ni ni-collection text-red',
              path: '/lightCommercial/quotes'
            }"
          >
          </sidebar-item>
          <sidebar-item
            :link="{
              name: 'Policies',
              icon: 'ni ni ni-collection text-red',
              path: '/lightCommercial/policies'
            }"
          >
          </sidebar-item>
          <!-- <sidebar-item
            v-if="can_see_fleet"
            :link="{
              name: 'Fleet',
              icon: 'ni ni ni-collection text-red',
              path: '/lightCommercial/fleet'
            }"
          > 
          </sidebar-item> -->
          <!-- <sidebar-item
            v-if="can_see_claims"
            :link="{
              name: 'Claims',
              icon: 'ni ni ni-collection text-red',
              path: '/lightCommercial/claims'
            }"
          >
          </sidebar-item>
          <sidebar-item
            v-if="can_see_claims"
            :link="{
              name: 'Submit Claim',
              icon: 'ni ni ni-collection text-red',
              path: '/lightCommercial/submitClaim'
            }"
          >
          </sidebar-item> -->
        </sidebar-item>

        <sidebar-item
          v-if="show_rs"
          :link="{
            name: 'Rideshare',
            icon: 'fas fa-car text-red',
            collapsed: true
          }"
        >

        <!-- <div  style="padding-left: 54px; font-size: 10pt; padding-right: 20px; font-style: italic;">As of 1 August 2024, Mercurien is no longer supporting Rideshare.  Please contact <a href="mailto:support@mercurien.com">support@mercurien.com</a> for further information.</div> -->
          <sidebar-item
            v-if="!isCurrentPageCreateQuote('rideShare') && canSeeRideShare()==true"
            :link="{
              name: 'Create Quote',
              path: '/rideShare/createQuote'
            }"
          >
          </sidebar-item>
          <a
            v-if="isCurrentPageCreateQuote('rideShare') && canSeeRideShare()==true"
            href="/rideShare/createQuote"
            aria-current="page"
            class="nav-link router-link-exact-active router-link-active"
            ><span class="nav-link-text">Create Quote</span>
          </a>
          <sidebar-item
          v-if="canSeeRideShare()==true"
            :link="{
              name: 'Quotes',
              path: '/rideShare/quotes'
            }"
          >
          </sidebar-item> 
          <sidebar-item
            :link="{
              name: 'Policies',
              path: '/rideShare/policies'
            }"
          >
          </sidebar-item>
          <!-- <sidebar-item
            v-if="can_see_fleet"
            :link="{
              name: 'Fleet',
              path: '/rideShare/fleet'
            }"
          >
          </sidebar-item> -->
          <!-- <sidebar-item
            v-if="can_see_claims"
            :link="{
              name: 'Claims',
              path: '/rideShare/claims'
            }"
          >
          </sidebar-item>
          <sidebar-item
            v-if="can_see_claims"
            :link="{
              name: 'Submit Claim',
              path: '/rideShare/submitClaim'
            }"
          >
          </sidebar-item> -->
        </sidebar-item>

        <sidebar-item
          v-if="can_see_fleet"
          :link="{
            name: 'Fleet',
            icon: 'fas fa-handshake text-red',
            collapsed: true
          }"
        >
          <sidebar-item
            :link="{
              name: 'Light Commercial',
              path: '/lightCommercial/fleet'
            }"
          >
          </sidebar-item>
          <!-- <sidebar-item
            :link="{
              name: 'Rideshare',
              path: '/rideshare/fleet'
            }"
          >
          </sidebar-item> -->
        </sidebar-item>

        <!-- <sidebar-item
          v-if="can_see_fleet_management"
          :link="{
            name: 'Fleet Management',
            icon: 'fas fa-handshake text-red',
            collapsed: true
          }"
        >
          <sidebar-item
            :link="{
              name: 'Mercurien Fleet Management',
              path: '/fleetManagement/managementServices'
            }"
          >
          </sidebar-item>
        </sidebar-item> -->

        <sidebar-item
          :link="{
            name: 'Reports',
            icon: 'ni ni ni ni-chart-bar-32 text-red',
            path: '/reports/yourInsuranceReports'
          }"
        >
      </sidebar-item>

        <sidebar-item
          v-if="brokerObj.Broker_super_admin"
          :link="{
            name: 'Admin',
            icon: 'ni ni ni-folder-17 text-red',
            collapsed: true
          }"
        >
          <sidebar-item
            :link="{ name: 'Brokers', path: '/admin/brokers' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Broker Organisations', path: '/admin/brokerOrgs' }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Insurance Brokers',
              path: '/admin/insuranceBrokers'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Customers', path: '/admin/customers' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Email Templates', path: '/admin/emailTemplates' }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Content Templates',
              path: '/admin/contentTemplates'
            }"
          ></sidebar-item>
        </sidebar-item>
      </template>

      <template slot="links-after">
        <hr class="my-3" />
      </template>
    </side-bar>
    <div class="main-content">
      <div class="body-container">
        <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

        <div @click="$sidebar.displaySidebar(false)">
          <fade-transition :duration="200" origin="center top" mode="out-in">
            <!-- your content here -->
            <router-view></router-view>
          </fade-transition>
        </div>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import { FadeTransition } from "vue2-transitions";
import { GlobalStore } from "../globalStore";
import { accessMethods } from "../helpers/accessPermissions";
import { authMethods } from "../helpers/auth";
import { appFunctions } from "../helpers/appFunctions";

export default {
  name: "DashboardLayout",
  components: {
    DashboardNavbar,
    ContentFooter,
    DashboardContent,
    FadeTransition
  },
  data() {
    return {
      can_see_claims: false,
      can_see_fleet: false,
      can_see_fleet_management: false,
      show_lc: false,
      show_rs: false,
      yiProducts: [],
    };
  },
  computed: {
    brokerObj() {
      console.log(GlobalStore.brokerObj);
      return GlobalStore.brokerObj;
    }
  },
  created() {
    this.$root.$refs.DashboardLayout = this;
  },
  methods: {
    async checkAccessPermission(permission) {
      var res = await accessMethods.checkAccessPermission(permission);
      return res.data;
    },
    canSeeRideShare() {

      return false;
      // if (GlobalStore.brokerObj.Broker_org_id == "brkOrg-0101") {
      //   return true;
      // } else {
      //   return false;
      // }
    },
    isCurrentPageCreateQuote(productType) {
      if (window.location.href.includes(`/${productType}/createQuote`)) {
        return true;
      } else {
        return false;
      }
    },
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    async disableOrEnabledMenu() {
      await authMethods.initAppInstance();
      this.show_lc = this.permissionEnabled(
        this.brokerObj.Show_lc,
        this.brokerObj.parentOrg.default_show_lc
      );
      this.show_rs = this.permissionEnabled(
        this.brokerObj.Show_rs,
        this.brokerObj.parentOrg.default_show_rs
      );
    },
    permissionEnabled(enableBroker, parentOrg) {
      let status = null;
      switch (enableBroker) {
        case true:
          status = true;
          break;
        case null:
          if (parentOrg) status = true;
          else status = false;
          break;
        case false:
          status = false;
          break;
      }
      return status;
    },
    isShowMenu(product) {
      if(product.Visible_to_broker_orgs.length > 0) {
        const brokerOrgs = product.Visible_to_broker_orgs.find(item => item === this.brokerObj.parentOrg.Broker_org_id)
        if (brokerOrgs) {
          return true
        } else {
          // check special brokers that not in finance org but still see vibs products
          if(product.Visible_to_brokers.length > 0) {
            const broker = product.Visible_to_brokers.find(item => item === this.brokerObj.Broker_id)
            return broker ? true : false
          }
          return false
        }
      }
      // } else {
      //   // if (product.Visible_to_brokers.length > 0) {
      //   //     const broker = product.Visible_to_brokers.find(item => item === this.brokerObj.Broker_id)
      //   //     return broker ? true : false
      //   // }
      //   return true
      // }
    },
    async disableOrEnabledProduct() {
      await appFunctions
        .crud_yiProduct("getAllProducts", {})
        .then(resp => {
          this.yiProducts = resp.data
        });
    }
  },
  async mounted() {
    this.initScrollbar();
    this.disableOrEnabledMenu();
    this.disableOrEnabledProduct();
    this.can_see_claims = await this.checkAccessPermission("can_see_claims");
    this.can_see_fleet = await this.checkAccessPermission("can_see_fleet");
    this.can_see_fleet_management = await this.checkAccessPermission(
      "can_see_fleet_management"
    );
  }
};
</script>
<style lang="scss" scoped>
a.yi-red {
  color: #ff4438 !important;
}
.body-container {
  min-height: 91.5vh;
}
</style>
